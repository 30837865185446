import config from "./config";
import environment from "./environment"; //this class is dynamically created by gulp
import BaseApp from "./core/base.app";
import { AppController } from "./controllers/app.controller";
import { LoadingController } from "./controllers/loading.controller";
import { ViewportController } from "./controllers/viewport.controller";
import { LayoutController } from "./controllers/layout.controller";
import UAParser from "ua-parser-js";

export default class App extends BaseApp {
  constructor() {
    super();
    this.config = config;
    this.environment = environment;
    this.settings = config.settings;
    this.settings.environment = this.environment.name;
    this.uaParser = new UAParser();
    this.deviceInfo = this.uaParser.getResult();
    this.app = this;

    if (this.environment.name == "production")
      window.console.log = function () {};

    this.init();
  }

  init() {
    this.addClass("l-main--ready");
    this.setClassName();
    this.setControllers();
    this.addGlobalListeners();
    this.ready();

    if (window.VWLandingAppReady) {
      setTimeout(() => {
        window.VWLandingAppsReady(this.app);
      }, 300);
    }
  }

  addGlobalListeners() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    window.addEventListener("scroll", (event) => {
      this.getController("layout").scrollHandler(event);
    });
  }

  setClassName() {
    let classNames = [];
    if (
      this.deviceInfo &&
      this.deviceInfo.browser &&
      this.deviceInfo.browser.name
    )
      classNames.push("browser-" + _.kebabCase(this.deviceInfo.browser.name));
    if (
      this.deviceInfo &&
      this.deviceInfo.device &&
      this.deviceInfo.device.type
    )
      classNames.push("device-" + _.kebabCase(this.deviceInfo.device.type));
    if (this.deviceInfo && this.deviceInfo.os && this.deviceInfo.os.name)
      classNames.push("os-" + _.kebabCase(this.deviceInfo.os.name));
    this.addClass(classNames);
  }

  setControllers() {
    this.createController("app", AppController);
    this.createController("loading", LoadingController);
    this.createController("viewport", ViewportController);
    this.createController("layout", LayoutController);
    this.dispatchEvent(App.EVENT_CONTROLLER_READY);
  }
}

App.EVENT_CONTROLLER_READY = "controller-ready";
App.EVENT_READY = "ready";
