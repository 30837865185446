import { DomComponent } from "../core/dom.component";

export default class HeaderComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-header');
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');

        this.init();
    }
    init() {
        document.getElementById("menu").addEventListener("click", toggleProductMenu, false);
        document.getElementById("openNavIcon").addEventListener("click", toggleNavMenu, false);
        document.getElementById("closeNavIcon").addEventListener("click", toggleNavMenu, false);
    }
}

const openMenuView = document.getElementById("openMenu");
const hamburgerIcon = document.getElementById("hamburger");
const closeIcon = document.getElementById("close");
const navResponsiveMenu = document.getElementById("navResponsiveMenu");
const openNavIcon = document.getElementById("openNavIcon");
const closeNavIcon = document.getElementById("closeNavIcon");
const headerOverlay = document.getElementById("headerOverlay");

function toggleProductMenu() {
    navResponsiveMenu.style.display= "none";
    closeNavIcon.style.display= "none";
    openNavIcon.style.display= "block";

    if (openMenuView.style.display === "none" || openMenuView.style.display === "") {
        headerOverlay.style.display = "block";
        openMenuView.style.display = "block";
        hamburgerIcon.style.display = "none";
        closeIcon.style.display = "block";
    } else {
        headerOverlay.style.display = "none";
        openMenuView.style.display = "none";
        closeIcon.style.display = "none";
        hamburgerIcon.style.display = "block";
    }
}

function toggleNavMenu() {
    openMenuView.style.display = "none";
    closeIcon.style.display = "none";
    hamburgerIcon.style.display = "block";

    if (navResponsiveMenu.style.display === "none" || navResponsiveMenu.style.display === "") {
        headerOverlay.style.display = "block";
        navResponsiveMenu.style.display = "block";
        closeNavIcon.style.display= "block";
        openNavIcon.style.display= "none";
    } else {
        headerOverlay.style.display = "none";
        navResponsiveMenu.style.display = "none";
        closeNavIcon.style.display= "none";
        openNavIcon.style.display= "block";
    }
}