import { DomComponent } from "../core/dom.component";
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider";

export default class ProductSliderComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-product-slider");
    this.layoutController = this.app.getController("layout");
    this.viewportController = this.app.getController("viewport");

    this.init();
  }
  init() {
    var slider = tns({
      container: "#product-slider",
      controls: true,
      center: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      loop: true,
      navAsThumbnails: true,
      responsive: {
        320: {
          items: 1,
          gutter: 24,
          edgePadding: 15
        },
        501: {
          items: 3,
          gutter: 24,
          edgePadding: -400
        },
        900: {
          items: 3,
          gutter: 24,
          edgePadding: -350
        },
        1100: {
          items: 3,
          gutter: 24,
          edgePadding: -300
        },
        1200: {
          items: 3,
          gutter: 24,
          edgePadding: -250
        },
        1400: {
          items: 3,
          gutter: 24,
          edgePadding: -200
        },
        1500: {
          items: 3,
          gutter: 24,
          edgePadding: -100
        }
      }
    });

    slider.goTo(1);
  }
}
