import { DomComponent } from "../core/dom.component";
export default class CookieBarComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, 'c-cookie-bar');
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.init();
    }
    init() {
        document.getElementById("acceptButton").addEventListener("click", acceptCookie, false);
    }
}
const cookie = document.getElementById("cookieBar");
function acceptCookie() {
    cookie.style.display= "none";
}