import { DomComponent } from "../core/dom.component";
import HeaderComponent from "../components/header.component";
export default class VideoBannerComponent extends DomComponent {
    constructor(app, element) {
        super(app, element, '.c-video-banner');
        this.layoutController = this.app.getController('layout');
        this.viewportController = this.app.getController('viewport');
        this.init();
    }
    init() {
        document.getElementById("videoButton").addEventListener("click", openPopup, false);
        document.getElementById("closeButton").addEventListener("click", closePopup, false);
        this.app.findChild('.c-header').then(element => {
            this.header = new HeaderComponent(this.app, element);
        });
    }
}
const video = document.getElementById("video");
const overlayVideo = document.getElementById("overlayVideo");
const header = document.getElementById("header");
const iFrameVideo = document.getElementById("iFrameVideo");
let source;
function openPopup(){
    if(source !== undefined){
        iFrameVideo.src = source;
    }
    header.style.display = "none";
    video.style.display = "none";
    overlayVideo.style.display = "block";
}
function closePopup(){
    header.style.display = "block";
    overlayVideo.style.display = "none";
    video.style.display = "block";
    source = iFrameVideo.src;
    iFrameVideo.src = '';
}