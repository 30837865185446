import { DomComponent } from "../core/dom.component";
import { tns } from "../../../../node_modules/tiny-slider/src/tiny-slider";

export default class LearnMoreComponent extends DomComponent {
  constructor(app, element) {
    super(app, element, "c-learn-more-slider");
    this.layoutController = this.app.getController("layout");
    this.viewportController = this.app.getController("viewport");

    this.getLearnMoreSlider();
  }
  getLearnMoreSlider() {
    tns({
      container: "#learn-more-slider",
      controls: true,
      center: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      loop: true,
      navAsThumbnails: true,
    });
  }
}
